import React, { useEffect } from 'react';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import PropTypes from 'prop-types';

import { COOKIE_DOMAIN } from '../../constants/CookieDomain';

const FingerPrint = ({ pathChange }) => {
  const expireDelay = 31536000;

  useEffect(() => {
    const fpPromise = FingerprintJS.load();
    return fpPromise
      .then((fp) => fp.get())
      .then((result) => {
        const setCookie = new Promise((resolve, reject) => {
          document.cookie =
            `x-fp=${result.visitorId};
            max-age=${expireDelay};
            domain=${COOKIE_DOMAIN}`;
          if (document.cookie.includes('x-fp')) {
            resolve();
          } else {
            reject();
          }
        });
        return setCookie
          .then(() => pathChange())
          .catch(() => console.error('can not set cookie'));
      });
  }, []);
  return <></>;
};

export default FingerPrint;

FingerPrint.propTypes = {
  pathChange: PropTypes.func,
};
