import React, { useContext } from 'react';
import { Link } from 'gatsby';

import FooterDropdawn from './FooterDropdawn';
import layoutContext from '../layoutContext';

import { FOOTER_PAGES } from '../../constants/Pages';

import './FooterPages.scss';

const FooterPages = () => {
  const { cult } = useContext(layoutContext);
  return (
    <div className='footer-pages'>
      <ul className='footer-pages-list'>
        {FOOTER_PAGES[cult].map(page => {
          if (page.children) {
            const { className, children, name } = page;
            return (
              <FooterDropdawn
                key={name}
                className={className}
                pages={children}
                name={name}
              />
            );
          } else {
            const { name, className, link } = page;
            return (
              <li key={link} className={`footer-list-item ${className}`}>
                {className === 'cabinet' ? (
                  <a
                    className='footer-page-link' key={link}
                    href={link}
                  >
                    {name}
                  </a>
                ) : (
                  <Link
                    className='footer-page-link' key={link}
                    to={link}
                  >
                    {name}
                  </Link>
                )}
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
};

export default FooterPages;
