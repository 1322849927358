import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import './FooterContacts.scss';
import { FOOTER_TOKENS } from '../../constants/Languages';
import layoutContext from '../layoutContext';

const FooterContacts = ({ phone }) => {
  const { cult } = useContext(layoutContext);
  return (
    <div className='footer-contacts'>
      <a href={`tel: +38${phone.replace(/[.*+?^${}()|[\]\\-\s]/g, '')}`} className='footer-contacts-phone'>
        {phone}
      </a>
      <p className='footer-contacts-time'>{FOOTER_TOKENS[cult].time}</p>
    </div>
  );
};

export default FooterContacts;

FooterContacts.propTypes = {
  phone: PropTypes.string
};
