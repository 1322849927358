import React, { useContext } from 'react';
import './FooterInfoBottom.scss';
import { FOOTER_TOKENS } from '../../constants/Languages';
import layoutContext from '../layoutContext';

const FooterInfoBottom = () => {
  const { cult } = useContext(layoutContext);
  return (
    <div className='footer-infobottom'>
      <p className='footer-infobottom-text'>{FOOTER_TOKENS[cult].infoTextBottom}</p>
      <p className='footer-infobottom-text'>{FOOTER_TOKENS[cult].adressInfoBottom}</p>
    </div>
  );
};

export default FooterInfoBottom;
