import {currentYear} from './Date';

export const LANGUAGES = ['uk', 'ru'];

export const HEADER_TOKENS = {
  ru: {
    orderCall: 'Заказать звонок'
  },
  uk: {
    orderCall: 'Замовити дзвінок'
  }
};

export const CALC_TOKENS = {
  ru: {
    title: 'Кредит на карточку за 15 минут',
    miniCalcTitle: 'Деньги за 15 минут',
    summ: 'Сумма',
    duration: 'Срок',
    getMoney: 'ПОЛУЧИТЬ ДЕНЬГИ',
    usePercent: '% за использование',
    firstCredit: ' на первый кредит',
    total: 'Всего к возврату',
    repetitiveCredit: 'Для повторного кредита',
    newClient: 'Первый кредит',
    oldClient: 'Повторный кредит'
  },
  uk: {
    title: 'Кредит на картку за 15 хвилин',
    miniCalcTitle: 'Гроші за 15 хвилин',
    summ: 'Сума',
    duration: 'Строк',
    getMoney: 'ОТРИМАТИ ГРОШІ',
    usePercent: '% за використання',
    firstCredit: ' на перший кредит',
    total: 'Всього до повернення',
    repetitiveCredit: 'Для повторного кредиту',
    newClient: 'Перший кредит',
    oldClient: 'Повторний кредит'
  }
};

export const FOOTER_TOKENS = {
  ru: {
    usefulLinks: 'Полезные ссылки',
    lastNews: 'Последние новости',
    tags: 'Тэги',
    time: 'график работы круглосуточно',
    infoText: 'Приостановка деятельности компании с 24.02.2022. До завершения действия военного положения ООО "ГЛОБАЛ КРЕДИТ" не осуществляет предоставление финансовых услуг и регулирование просроченной задолженности. Военнослужащим. С целью принятия Финансовой Компанией мероприятий, направленных на выполнение пункта 15 статьи 14 Закона Украины «О социальной и правовой защите военнослужащих и членов их семей», предлагаем направлять обращения и подтверждающие документы по адресу borg@globalcredit.ua',
    infoTextBottom: 'Как рассчитать процентную ставку по кредиту? Сервис Глобал Кредит предлагает кредиты неограниченным сроком, если иное не предусмотрено условиями кредитного договора. Например, если Вы берете кредит на сумму 1000,00 грн. на 65 дней, то возвращаете 1000,00 грн. (Сумма кредита) + 1300,00 грн. (Проценты по кредиту за 65 дней) = 2300,00 грн., реальная максимальная годовая процентная ставка составляет 730% годовых. Минимальная процентная ставка составляет 0,01% за 1 день пользования кредитом: если Вы берете кредит на сумму 1000,00 грн. на 65 дней, то возвращаете 1000,00 грн. (сумма кредита) + 6,50 грн. (проценты по кредиту за 65 дней) = 1006,50 грн., реальная минимальная годовая процентная ставка составляет 3,65% годовых. Максимальный начальный срок кредита не ограничен, но клиент имеет право погасить кредит досрочно или воспользоваться своим правом продлить срок пользования кредитом неограниченное количество раз.',
    adressInfoBottom: 'Адрес: 04050, Украина, г. Киев, ул. Юрия Ильенко, 81'
  },
  uk: {
    usefulLinks: 'Корисні посилання',
    lastNews: 'Останні новини',
    tags: 'Теги',
    time: 'графік роботи цілодобово',
    infoText: 'Зупинка діяльністі компанії з 24.02.2022. В період дії воєнного стану ТОВ ГЛОБАЛ КРЕДИТ не здійснює надання фінансових послуг та врегулювання простроченної заборгованності. Військовослужбовцям. З метою вжиття Фінансовою Компанією заходів, спрямованих на виконання пункту 15 статті 14 Закону України «Про соціальний і правовий захист військовослужбовців і членів їх сімей», пропонуємо направляти звернення та підтверджуючі документи за електронною адресою borg@globalcredit.ua',
    infoTextBottom: 'Як розрахувати відсоткову ставку за кредитом? Сервіс Глобал Кредит пропонує кредити на необмежений термін, якщо інше не передбачено умовами кредитного договору. Наприклад, якщо Ви берете кредит на суму 1000,00 грн. на 65 днів, то повертаєте 1000,00 грн. (сума кредиту) + 1300,00 грн. (відсотки за кредитом за 65 днів) = 2300,00 грн., реальна максимальна річна відсоткова ставка становить 730% річних. Мінімальна відсоткова складає 0,01% за 1 день користування кредитом: якщо Ви берете кредит на суму 1000,00 грн. на 65 днів, то повертаєте 1000,00 грн. (сума кредиту) + 6,50 грн. (відсотки за кредитом за 65 днів) = 1006,50 грн., реальна мінімальна річна відсоткова ставка становить 3,65% річних. Максимальний початковий строк кредиту не обмежено, але клієнт має право погасити кредит достроково або скористатися своїм правом продовжити строк користування кредитом необмежену кількість разів.',
    adressInfoBottom: 'Адреса: 04050, Україна, м. Київ, вул. Юрія Іллєнка, 81'
  }
};

export const FOOTER_LICENSE = {
  ru: {
    title: `© 2012 - ${currentYear} ООО «ГЛОБАЛ КРЕДИТ»`,
    content: 'Лицензия б/н получена 26.01.2017 "Предоставление средств в займ, в том числе и на условиях финансового кредита". ЕГРПОУ 38266014',
    tradeMarkInfo: 'ТМ "ГЛОБАЛ КРЕДИТ", ТМ "Твої Гроші", ТМ "Супер Гроші", ТМ "Швидко, просто, до зарплати", ТМ "VSEM"'
  },
  uk: {
    title: `© 2012 - ${currentYear} ТОВ «ГЛОБАЛ КРЕДИТ»`,
    content: 'Ліцензія б/н отримана 26.01.2017 "Надання коштів у позику, в тому числі і на умовах фінансового кредиту". ЄДРПОУ 38266014',
    tradeMarkInfo: 'ТМ "ГЛОБАЛ КРЕДИТ", ТМ "Твої Гроші", ТМ "Супер Гроші", ТМ "Швидко, просто, до зарплати", ТМ "VSEM"'
  }
};

export const WEEKDAYS = {
  ru: [
    'Воскресенье',
    'Понедельник',
    'Вторник',
    'Среда',
    'Четверг',
    'Пятница',
    'Суббота'
  ],
  uk: [
    'Неділя',
    'Понеділок',
    'Вівторок',
    'Середа',
    'Четвер',
    'П\'ятниця',
    'Субота'
  ]
};

export const PAGES = {
  article: {
    ru: 'Статьи',
    uk: 'Статті'
  },
  company_allukraine: {
    ru: 'Участники рынка микрокредитования Украины',
    uk: 'Учасники ринку мікрокредитування України',
  },
  stocks: {
    ru: 'Акции',
    uk: 'Акції',
  },
  news: {
    ru: 'Новости',
    uk: 'Новини',
  },
};

export const TEMPLATE_TOKENS = {
  ru: {
    stocks: 'Акции',
    lastNews: 'Последние новости',
    lastArticles: 'Последние статьи',
    microcreditParticipants: 'Участники рынка микрокредитования Украины',
    news: 'Новости',
    articles: 'Статьи',
    mainPage: 'Главная',
    fastCredits: 'Быстрые кредиты в городах Украины',
    nextPage: 'Следующая',
    prevPage: 'Предыдущая',
    readMore: 'Читать далее'
  },
  uk: {
    stocks: 'Акції',
    lastNews: 'Останні новини',
    lastArticles: 'Останні статті',
    microcreditParticipants: 'Учасники ринку мікрокредитувания України',
    news: 'Новини',
    articles: 'Статті',
    mainPage: 'Головна',
    fastCredits: 'Швидкі кредити у містах України',
    nextPage: 'Наступна',
    prevPage: 'Попередня',
    readMore: 'Читати далі'
  }
};

export const MODAL_TOKENS = {
  ru: {
    contact: 'Контакное лицо / Компания',
    callTime: 'Зручний час для дзвінка',
    necessary: 'Необходимо заполнить поле',
    inputName: 'Введите Ваше имя / компанию',
    inputPhone: 'Введите Ваш номер телефона',
    inputMessage: 'Введите текст сообщения',
  },
  uk: {
    contact: 'Контактна особа / Компанія',
    callTime: 'Зручний час для дзвінка',
    necessary: 'Необхідно заповнити поле',
    inputName: 'Введіть Ваше ім\'я / компанію',
    inputPhone: 'Введіть Ваш номер телефона',
    inputMessage: 'Введіть текст повідомлення',
  }
};

export const PARTNERS_TOKENS = {
  ru: {
    partnersAddresses: 'Адреса наших партнёров',
  },
  uk: {
    partnersAddresses: 'Адреса наших партнерів',
  }
};

export const VOTING_TOKENS = {
  ru: {
    rateArticle: 'Оцените статью!',
    thanksForVote: 'Cпаcибо за Ваш голос!',
    thanks: 'Спасибо за оставленный рейтинг!',
    countVoutes: 'Голосов',
    of: 'c',
    average: 'среднем',
  },
  uk: {
    rateArticle: 'Оцініть статтю!',
    thanksForVote: 'Дякуємо за Ваш голос!',
    thanks: 'Дякуємо за залишений рейтинг',
    countVoutes: 'Голосів',
    of: 'з',
    average: 'середньому',
  }
};

export const DEFAULT_TITLE = {
  ru: 'Глобал Кредит: Быстрые кредиты на карту, онлайн займы в Украине',
  uk: 'Глобал Кредит: Швидкі кредити на карту, онлайн позики в Україні',
};

export const DEFAULT_DESCRIPTION = {
  ru: 'Быстрые онлайн–кредиты в Украине ✓ Минимум времени ✓ Круглосуточно ✓ Без справок ✓ Паспорт.',
  uk: 'Швидкі онлайн-кредити в Україні ✓ Мінімум часу ✓ Цілодобово ✓ Без довідок ✓ Паспорт та код.',
};

export const ARTICLES_DESCRIPTION = {
  ru: '➦ Акции, статьи, новости ➦ Кредиты онлайн в Украине ➦ Всего за несколько минут.',
  uk: '➦ Акції, статті, новини ➦ Кредити онлайн в Україні ➦ Всього за кілька хвилин.',
};

export const CITIES_TITLE = {
  ru: {
    firstPart: 'Кредиты: г.',
    secondPart: 'Онлайн на карту, займы без справок о доходах – Глобал Кредит',
  },

  uk: {
    firstPart: 'Кредити: м.',
    secondPart: 'Онлайн на карту, позики без довідок про доходи - Глобал Кредит',
  },
};

export const CITIES_DESCRIPTION = {
  ru: {
    firstPart: 'Глобал Кредит – срочные кредиты в городе',
    secondPart: '➦ Онлайн займы на карту ➦ Деньги в долг без залога, справок о доходах ➦ Паспорт и код.',
  },

  uk: {
    firstPart: 'Глобал Кредит - термінові кредити в місті',
    secondPart: '➦ Онлайн позики на карту ➦ Гроші в борг без застави, довідок про доходи ➦ Паспорт і код.',
  },
};

export const COMPETITIORS_TITLE = {
  ru: 'отзывы, личный кабинет, контакты, кредит, пролонгация',
  uk: 'відгуки, особистий кабінет, контакти, кредит, пролонгація',
};

export const COMPETITIORS_DESCRIPTION = {
  ru: {
    firstPart: 'Онлайн кредиты от',
    secondPart: '➦ Отзывы, вход в личный кабинет, сайт, контакты, телефон, реквизиты ➦ Без справок о доходах и залога ➦ Паспорт и код.',
  },

  uk: {
    firstPart: 'Онлайн кредити від',
    secondPart: '➦ Відгуки, вхід в особистий кабінет, сайт, контакти, телефон, реквізити ➦ Без довідок про доходи і застави ➦ Паспорт і код.',
  },
};

export const RUBRICS_TITLE = {
  ru: 'Блог о кредитах, деньгах, финансах – Глобал Кредит',
  uk: 'Блог про кредити, гроші, фінанси - Глобал Кредит',
};

export const RUBRICS_DESCRIPTION = {
  ru: '➦ Статьи и публикации о кредитах и деньгах ➦ Советы и рекомендации финансовых специалистов.',
  uk: '➦ Статті та публікації про кредити та гроші ➦ Поради та рекомендації фінансових фахівців',
};

export const MONTHS = {
  ru: [
    'Января',
    'Февраля',
    'Марта',
    'Апреля',
    'Мая',
    'Июня',
    'Июля',
    'Августа',
    'Сентября',
    'Октября',
    'Ноября',
    'Декабря'
  ],
  uk: [
    'Січня',
    'Лютого',
    'Березня',
    'Квітня',
    'Травня',
    'Червня',
    'Липня',
    'Серпня',
    'Вересня',
    'Жовтня',
    'Листопада',
    'Грудня'
  ],
};

export const NOTIFICATIONS_TOKENS = {
  ru: {
    title: 'Разрешите сайту globalcredit.ua отправлять вам уведомления на рабочий стол',
    decline: 'Нет, спасибо',
    continue: 'Продолжить',
    button: 'Подписаться на уведомления'
  },
  uk: {
    title: 'Дозволити сайту globalcredit.ua відправляти повідомлення на робочий стіл',
    decline: 'Ні, дякую',
    continue: 'Продовжити',
    button: 'Пiдписатися на повiдомлення'
  }
};
