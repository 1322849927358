import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { HEADER_PAGES } from '../../constants/Pages';
import { PHONES } from '../../constants/Phones';
import { FOOTER_ICONSET } from '../../constants/SocialIcons';
import DropdawnLink from './DropdawnLink';
import Phone from './Phone';
import SocialLinks from '../Common/SocialLinks/SocialLinks';
import layoutContext from '../layoutContext';

import './MobileMenu.scss';

const MobileMenu = ({
  isMobileOpen,
  isMenuOpen,
  openMobile,
  openMobileMenu
}) => {
  const { cult } = useContext(layoutContext);
  return (
    <>
      {isMenuOpen && (
        <nav className={`menu ${isMobileOpen ? 'menu-open' : 'menu-close'}`}>
          <ul className='menu-items'>
            {HEADER_PAGES[cult].map(page => {
              if (page.children) {
                const { children, className, name } = page;
                return (
                  <DropdawnLink
                    key={name}
                    pages={children}
                    className={className}
                    name={name}
                    openMobile={openMobile}
                    openMobileMenu={openMobileMenu}
                    isMobileOpen={isMobileOpen}
                  />
                );
              } else {
                const { link, className, name } = page;
                if (className === 'cabinet') {
                  return (
                    <li key={link} className={`menu-item ${className}`}>
                      <a className='menu-link' href={link}>
                        {name}
                      </a>
                    </li>
                  );
                } else {
                  return (
                    <li key={link} className={`menu-item ${className}`}>
                      <Link
                        className='menu-link'
                        onClick={
                          isMobileOpen &&
                          (() => {
                            openMobile(isMobileOpen => !isMobileOpen);
                            openMobileMenu();
                          })
                        }
                        to={link}
                      >
                        {name}
                      </Link>
                    </li>
                  );
                }
              }
            })}
          </ul>
          <div className='phones'>
            {PHONES.map(phone => (
              <Phone key={phone} phone={phone} />
            ))}
          </div>
          <SocialLinks socialSet={FOOTER_ICONSET} />
        </nav>
      )}
    </>
  );
};

export default MobileMenu;

MobileMenu.propTypes = {
  isMobileOpen: PropTypes.bool,
  isMenuOpen: PropTypes.bool,
  openMobileMenu: PropTypes.func,
  openMobile: PropTypes.func
};
