import React from 'react';
import PropTypes from 'prop-types';

import './SocialLinks.scss';

const SocialLinks = ({ socialSet }) => (
  <div className='social-links'>
    {socialSet.map(({ alt, socialIcon, link }) => (
      <a
        className='social-link' href={link}
        key={link}
      >
        <img src={socialIcon} alt={alt} />
      </a>
    ))}
  </div>
);

export default SocialLinks;

SocialLinks.propTypes = {
  socialSet: PropTypes.array
};
