import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const DropdawnLink = ({
  className,
  name,
  pages,
  openMobile,
  openMobileMenu,
  isMobileOpen
}) => {
  const [isDropdawnOpen, openDropdawn] = useState(false);

  const dropdawn = useRef(null);
  const dropdawnLink = useRef(null);

  useEffect(() => {
    document.addEventListener('click', clickOutside);
  }, []);


  useEffect(() =>
    () => {
      document.removeEventListener('click', clickOutside);
    }, []);

  function clickOutside(e) {
    if (dropdawn &&
      !dropdawn.current.contains(e.target) &&
      !dropdawnLink.current.contains(e.target)
    ) {
      openDropdawn(false);
    }
  }

  return (
    <li
      className={`menu-item ${className}`}
      onClick={() => openDropdawn(!isDropdawnOpen)}
      ref={dropdawnLink}
    >
      <a className='dropdawn-link'>{name}</a>
      <ul
        className={`
          dropdawn-items
          ${isDropdawnOpen ? 'dropdawn-items-open' : 'dropdawn-items-close'}
        `}
        ref={dropdawn}
      >
        {pages.map(({ link, name }) => (
          <li
            key={link} className='dropdawn-item'
            onClick={
              isDropdawnOpen
                ? () => {
                  openDropdawn(!isDropdawnOpen);
                }
                : undefined
            }
          >
            <Link
              className='menu-link'
              onClick={
                isMobileOpen
                  ? () => {
                    openMobile(isMobileOpen => !isMobileOpen);
                    openMobileMenu();
                  }
                  : undefined
              }
              to={link}
            >
              {name}
            </Link>
          </li>
        ))}
      </ul>
    </li>
  );
};

export default DropdawnLink;

DropdawnLink.propTypes = {
  isMobileOpen: PropTypes.bool,
  className: PropTypes.string,
  name: PropTypes.string,
  pages: PropTypes.array,
  openMobileMenu: PropTypes.func,
  openMobile: PropTypes.func
};
