/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-len */
/* eslint-disable no-cond-assign */
/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect, useCallback } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import UsefulLinks from './UsefulLinks';
import Tags from './Tags';
import SocialLinks from '../Common/SocialLinks/SocialLinks';
import FooterContacts from './FooterContacts';
import FooterInfo from './FooterInfo';
import FooterInfoBottom from './FooterInfoBottom';
import Licensing from './Licensing';
import FooterPages from './FooterPages';
import LastNews from './LastNews';
import layoutContext from '../layoutContext';
import NotificationsModal from '../NotificationsModal/NotificationsModal';
import { apiTrackLink } from '../../constants/ApiLink';

import { FOOTER_ICONSET } from '../../constants/SocialIcons';
import { FOOTER_TOKENS } from '../../constants/Languages';
import { USEFUL_LINKS } from '../../constants/Pages';

import './Footer.scss';

const Footer = () => {
  const DAYS = 60;

  const { cult } = useContext(layoutContext);
  const [isModalOpen, openModal] = useState(false);
  const [path, setPath] = useState();
  const [counter] = useState({ summCount: 0, daysCount: 0 });

  if (typeof document !== 'undefined') {
    document.cookie =
      `counter=${JSON.stringify(counter)}; path=/`;
  }

  useEffect(() => {
    const today = new Date();
    const deleteCookies = new Date();
    deleteCookies.setTime(today.getTime() + 3600000 * 24 * DAYS);
    if (Object.keys(getQueryParams(location.search)).length) {
      document.cookie =
        `getParams = ${JSON.stringify(getQueryParams(location.search))}; expires = ${deleteCookies.toUTCString()}; path = /`;
    } else if (!Object.keys(getQueryParams(location.search)).length && !getCookie('getParams')) {
      document.cookie =
        `getParams=${JSON.stringify({})}; expires=${deleteCookies.toUTCString()}; path=/`;
    } else {
      `getParams=${JSON.stringify({})}; expires=${deleteCookies.toUTCString()}; path=/`;
    }
  }, []);

  useEffect(() => {
    try {
      if (typeof Notification !== undefined) {
        if (!getCookie('notications') && Notification.permission === 'default') {
          openModal(true);
        }
      } else {
        if (!getCookie('notications')) {
          openModal(true);
        }
      }
    } catch {
      console.log('No notifiactions availible in browser');
    }
  }, []);

  function getQueryParams(link) {
    link = link.split('+').join(' ');

    const params = {};
    let tokens;
    const re = /[?&]?([^=]+)=([^&]*)/g;

    while (tokens = re.exec(link)) {
      params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
    }

    return params;
  }

  const pathChange = useCallback(
    () => {
      const body = {
        'x-fp': getCookie('x-fp'),
      };
      if (typeof window !== 'undefined' && path !== window.location.pathname && getCookie('x-fp')) {
        setPath(window.location.pathname);
        fetch(apiTrackLink, {
          method: 'POST',
          mode: 'no-cors',
          cache: 'no-cache',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        })
          .then(response => response.json())
          .catch(error => error);
      }
    },
    [path, counter]
  );

  const getCookie = useCallback(
    (name) => {
      if (typeof document !== 'undefined') {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) {
          return parts.pop().split(';').shift();
        } else {
          return false;
        }
      }
    }, []
  );

  pathChange();

  return (
    <StaticQuery
      query={graphql`
        query {
          pages: allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { fileAbsolutePath: { regex: "/content/" } }
          ) {
            totalCount
            edges {
              node {
                frontmatter {
                  title
                  headLine
                  path
                  tags
                  lang
                  category
                  date
                }
              }
            }
          }
          popularTags: allMarkdownRemark(
            sort: {fields: [frontmatter___order], order: ASC}, 
            filter: {frontmatter: {order: {ne: null} anchor: {ne: null}}}) {
            totalCount
            edges {
              node {
                frontmatter {
                  anchor
                  path
                  lang
                }
              }
            }
          }
        }
      `}
      render={({ pages: { edges }, popularTags: { edges: tagsEdges } }) => {
        const { usefulLinks, lastNews, tags, time } = FOOTER_TOKENS[cult];

        return (
          <footer>
            <div className='container'>
              <div className='primary-footer-wrapper'>
                <div className='primary-footer'>
                  <div className='footer-leftSide'>
                    <UsefulLinks
                      title={usefulLinks}
                      pages={USEFUL_LINKS[cult]}
                      cult={cult}
                    />
                    <LastNews
                      title={lastNews}
                      edges={edges}
                      cult={cult}
                    />
                  </div>
                  <div className='footer-rightSide'>
                    <Tags
                      title={tags}
                      edges={tagsEdges}
                      cult={cult}
                    />
                    <div className='footer-social'>
                      <SocialLinks socialSet={FOOTER_ICONSET} />
                      <FooterContacts
                        time={time}
                        phone='0 800 807-888'
                      />
                    </div>
                    <div className='footer-info'>
                      <FooterInfo
                        cult={cult}
                      />
                    </div>
                  </div>
                </div>
                <NotificationsModal openModal={openModal} />
                <FooterInfoBottom
                  cult={cult}
                />
              </div>
              <div className='secondary-footer'>
                <Licensing />
                <FooterPages />
              </div>
            </div>
          </footer>
        );
      }}
    />
  );
};

export default Footer;
