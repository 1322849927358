import React from 'react';
import PropTypes from 'prop-types';
import Portal from './Portal';
import ModalForm from './ModalForm';
import CloseModal from './CloseModal';

import './Modal.scss';

const Modal = ({ isModalOpen, openModal, fixMenu }) => {
  return (
    <Portal>
      <div className='modal'>
        <ModalForm />
        <CloseModal
          openModal={openModal}
          isModalOpen={isModalOpen}
          fixMenu={fixMenu}
        />
      </div>
    </Portal>
  );
};

export default Modal;

Modal.propTypes = {
  isModalOpen: PropTypes.bool,
  openModal: PropTypes.func,
  fixMenu: PropTypes.func
};
