import React from 'react';
import PT from 'prop-types';
import MainLayout from './layout';

const Layout = ({ children, ...rest }) =>
  rest.pageContext.noLayout ? (
    children
  ) : (
    <MainLayout {...rest}>{children}</MainLayout>
  );

Layout.propTypes = {
  children: PT.node
};

export default Layout;
