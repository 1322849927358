import React from 'react';

import PropTypes from 'prop-types';

const Button = ({ content, className, onClick }) => (
  <button
    type='button' onClick={onClick}
    className={className}
  >{content}</button>
);

export default Button;

Button.propTypes = {
  content: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};
