import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';

import Button from './Button';

import layoutContext from '../layoutContext';

import { NOTIFICATIONS_TOKENS } from '../../constants/Languages';

import './NotificationsModal.scss';

const NotificationsModal = ({ openModal }) => {
  const { cult } = useContext(layoutContext);
  const openNotificationsModal = useCallback(() => {
    openModal(false);
    window.open('/notifications', 'popUpWindow', 'height=500,width=600,left=100,top=100,resizable=no,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no, status=no');
  },
  [cult],
  );
  return (
    <div className='buttons-container'>
      <Button
        content={NOTIFICATIONS_TOKENS[cult].button}
        onClick={openNotificationsModal}
        className='subscribe-to-push-button hidden'
      />
    </div>
  );
};

export default NotificationsModal;

NotificationsModal.propTypes = {
  openModal: PropTypes.func,
};
