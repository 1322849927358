import React, { useState, useCallback, useContext } from 'react';

import CabinetIcon from './CabinetIcon';
import Logo from './Logo';
import LanguageSelect from './LanguageSelect';
import MenuBtn from './MenuBtn';
import MobileMenu from './MobileMenu';
import Menu from './Menu';
import Contacts from './Contacts';
import Modal from './Modal';

import layoutContext from '../layoutContext';
import { cabinetlinkRu, cabinetlinkUa } from '../../constants/Pages';

import cabinet from '../../images/header/cabinet.svg';

import './Header.scss';

const Header = () => {
  const [language, selectLanguage] = useState('ru');
  const [isMobileOpen, openMobile] = useState(false);
  const [isMenuOpen, openMenu] = useState(false);
  const [isModalOpen, openModal] = useState(false);

  const fixMenu = useCallback(
    () => { document.getElementsByTagName('body')[0].classList.toggle('fixed'); },
    [],
  );


  function openMobileMenu() {
    setTimeout(() => {
      openMenu(isMenuOpen => !isMenuOpen);
      fixMenu();
    }, 100);
  }

  const { cult } = useContext(layoutContext);

  return (
    <>
      <header>
        {!isMenuOpen && (
          <Contacts
            isModalOpen={isModalOpen}
            openModal={openModal}
            fixMenu={fixMenu}
          />
        )}
        <div className='header-wrapper'>
          <div className='menu-container'>
            <div className='header-leftside'>
              <Logo />
              {!isMenuOpen && (
                <Menu
                  isMobileOpen={isMobileOpen}
                  isMenuOpen={isMenuOpen}
                  openMobile={openMobile}
                  openMobileMenu={openMobileMenu}
                />
              )}
            </div>
            <div className='header-container'>
              <CabinetIcon link={cult === 'ru' ? cabinetlinkRu : cabinetlinkUa} cabinet={cabinet} />
              <LanguageSelect
                language={language}
                selectLanguage={selectLanguage}
              />
              <MenuBtn
                isMobileOpen={isMobileOpen}
                openMobileMenu={openMobileMenu}
                openMobile={openMobile}
              />
            </div>
          </div>
        </div>
        <MobileMenu
          isMobileOpen={isMobileOpen}
          isMenuOpen={isMenuOpen}
          openMobile={openMobile}
          openMobileMenu={openMobileMenu}
        />
      </header>
      {isModalOpen && (
        <Modal
          isModalOpen={isModalOpen}
          openModal={openModal}
          fixMenu={fixMenu}
        />
      )}
    </>
  );
};

export default Header;
