import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { HEADER_PAGES } from '../../constants/Pages';
import DropdawnLink from './DropdawnLink';
import layoutContext from '../layoutContext';

import './Menu.scss';

const Menu = ({ isMobileOpen, openMobile, openMobileMenu }) => {
  const { cult } = useContext(layoutContext);
  return (
    <ul className='desktop-menu'>
      {HEADER_PAGES[cult].map(page => {
        if (page.children) {
          const { children, className, name } = page;
          return (
            <DropdawnLink
              key={name}
              pages={children}
              className={className}
              name={name}
              openMobile={openMobile}
              openMobileMenu={openMobileMenu}
              isMobileOpen={isMobileOpen}
            />
          );
        } else {
          const { link, className, name } = page;
          if (className === 'cabinet') {
            return (
              <li key={link} className={`menu-item ${className}`}>
                <a className='menu-link' href={link}>
                  {name}
                </a>
              </li>
            );
          } else {
            return (
              <li key={link} className={`menu-item ${className}`}>
                <Link
                  className='menu-link'
                  onClick={
                    isMobileOpen
                      ? () => {
                        openMobile(isMobileOpen => !isMobileOpen);
                        openMobileMenu();
                      }
                      : undefined
                  }
                  to={link}
                >
                  {name}
                </Link>
              </li>
            );
          }
        }
      })}
    </ul>
  );
};

export default Menu;

Menu.propTypes = {
  isMobileOpen: PropTypes.bool,
  isMenuOpen: PropTypes.bool,
  openMobileMenu: PropTypes.func,
  openMobile: PropTypes.func
};
