import React, { useEffect, useCallback } from 'react';

import { COOKIE_DOMAIN } from '../../constants/CookieDomain';

const LoadingComponent = () => {
  const expireDelay = 31536000;

  const getCookie = useCallback(
    (name) => {
      if (typeof document !== 'undefined') {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) {
          return parts.pop().split(';').shift();
        } else {
          return false;
        }
      }
    }, []
  );

  useEffect(() => {
    if (getCookie('x-fp')) {
      document.cookie =
        `x-fp=${getCookie('x-fp')}; max-age=${expireDelay}; domain=${COOKIE_DOMAIN}`;
    }
  }, []);

  return <></>;
};

export default LoadingComponent;
