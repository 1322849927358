import React from 'react';
import PropTypes from 'prop-types';


import './CabinetIcon.scss';

const CloseIcon = ({ link, cabinet }) => (
  <a href={link} className='cabinet-link'>
    <img
      className='cabinet-image' src={cabinet}
      alt='globalcredit cabinet'
    />
  </a>
);

export default CloseIcon;

CloseIcon.propTypes = {
  link: PropTypes.string,
  cabinet: PropTypes.string,
};
