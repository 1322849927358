// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-category-akcii-jsx": () => import("./../../../src/pages/category/akcii.jsx" /* webpackChunkName: "component---src-pages-category-akcii-jsx" */),
  "component---src-pages-category-novosti-jsx": () => import("./../../../src/pages/category/novosti.jsx" /* webpackChunkName: "component---src-pages-category-novosti-jsx" */),
  "component---src-pages-category-stati-jsx": () => import("./../../../src/pages/category/stati.jsx" /* webpackChunkName: "component---src-pages-category-stati-jsx" */),
  "component---src-pages-company-allukraine-jsx": () => import("./../../../src/pages/company-allukraine.jsx" /* webpackChunkName: "component---src-pages-company-allukraine-jsx" */),
  "component---src-pages-uk-category-akcii-jsx": () => import("./../../../src/pages/uk/category/akcii.jsx" /* webpackChunkName: "component---src-pages-uk-category-akcii-jsx" */),
  "component---src-pages-uk-category-novosti-jsx": () => import("./../../../src/pages/uk/category/novosti.jsx" /* webpackChunkName: "component---src-pages-uk-category-novosti-jsx" */),
  "component---src-pages-uk-category-stati-jsx": () => import("./../../../src/pages/uk/category/stati.jsx" /* webpackChunkName: "component---src-pages-uk-category-stati-jsx" */),
  "component---src-pages-uk-company-allukraine-jsx": () => import("./../../../src/pages/uk/company-allukraine.jsx" /* webpackChunkName: "component---src-pages-uk-company-allukraine-jsx" */),
  "component---src-templates-article-jsx": () => import("./../../../src/templates/article.jsx" /* webpackChunkName: "component---src-templates-article-jsx" */),
  "component---src-templates-city-page-jsx": () => import("./../../../src/templates/city-page.jsx" /* webpackChunkName: "component---src-templates-city-page-jsx" */),
  "component---src-templates-competitor-jsx": () => import("./../../../src/templates/competitor.jsx" /* webpackChunkName: "component---src-templates-competitor-jsx" */),
  "component---src-templates-default-jsx": () => import("./../../../src/templates/default.jsx" /* webpackChunkName: "component---src-templates-default-jsx" */),
  "component---src-templates-news-jsx": () => import("./../../../src/templates/news.jsx" /* webpackChunkName: "component---src-templates-news-jsx" */),
  "component---src-templates-notifications-jsx": () => import("./../../../src/templates/notifications.jsx" /* webpackChunkName: "component---src-templates-notifications-jsx" */),
  "component---src-templates-popular-page-jsx": () => import("./../../../src/templates/popular-page.jsx" /* webpackChunkName: "component---src-templates-popular-page-jsx" */),
  "component---src-templates-rubric-jsx": () => import("./../../../src/templates/rubric.jsx" /* webpackChunkName: "component---src-templates-rubric-jsx" */),
  "component---src-templates-stock-jsx": () => import("./../../../src/templates/stock.jsx" /* webpackChunkName: "component---src-templates-stock-jsx" */)
}

