import React, { useContext } from 'react';
import { Link } from 'gatsby';

import './Logo.scss';

import logo from '../../images/header/logo.svg';
import logoMobile from '../../images/header/logo-mobile.svg';
import layoutContext from '../layoutContext';

const Logo = () => {
  const { cult } = useContext(layoutContext);

  return (
    <Link to={cult === 'ru' ? '/' : '/uk'}>
      <img
        className='logo logo--desktop' src={logo}
        alt='global credit'
      />
      <img
        className='logo logo--mobile' src={logoMobile}
        alt='global credit'
      />
    </Link>
  );
};

export default Logo;
