/* eslint-disable sonarjs/no-duplicate-string */
const uppercaselinkclass = 'uppercase-link';
export const cabinetlinkRu = 'https://cabinet.globalcredit.ua/?type=dep&lang=ru';
export const cabinetlinkUa = 'https://cabinet.globalcredit.ua/?type=dep&lang=uk&language=uk';


export const FOOTER_PAGES = {
  ru: [
    {
      name: 'Кредит',
      className: 'dropdawn',
      children: [
        { name: 'Как получить кредит?', link: '/get-loan' },
        { name: 'Как погасить кредит?', link: '/pay-loan' }
      ]
    },
    {
      name: 'SMS-кредит',
      className: uppercaselinkclass,
      link: '/sms-kredit'
    },
    { name: 'Контакты', className: '', link: '/contacts' },
    {
      name: 'Кабинет',
      className: 'cabinet',
      link: cabinetlinkRu
    }
  ],
  uk: [
    {
      name: 'Кредит',
      className: 'dropdawn',
      children: [
        { name: 'Як отримати кредит?', link: '/uk/get-loan' },
        { name: 'Як погасити кредит?', link: '/uk/pay-loan' }
      ]
    },
    {
      name: 'SMS-кредит',
      className: uppercaselinkclass,
      link: '/uk/sms-kredit'
    },
    { name: 'Контакти', className: '', link: '/uk/contacts' },
    {
      name: 'Кабінет',
      className: 'cabinet',
      link: cabinetlinkUa
    }
  ]
};

export const HEADER_PAGES = {
  ru: [
    {
      name: 'Кредит',
      className: 'dropdawn',
      children: [
        { name: 'Как получить кредит?', link: '/get-loan' },
        { name: 'Как погасить кредит?', link: '/pay-loan' }
      ]
    },
    {
      name: 'Кредит за 60 секунд',
      className: 'uppercase-link',
      link: '/sms-kredit'
    },
    { name: 'Акции', className: '', link: '/category/akcii' },
    {
      name: 'Новости и статьи',
      className: 'mobile-item dropdawn',
      children: [
        { name: 'Новости', link: '/category/novosti' },
        { name: 'Статьи', className: '', link: '/category/stati' }
      ]
    },
    { name: 'Контакты', className: '', link: '/contacts' },
    {
      name: 'Кабинет',
      className: 'cabinet',
      link: cabinetlinkRu
    }
  ],
  uk: [
    {
      name: 'Кредит',
      className: 'dropdawn',
      children: [
        { name: 'Як отримати кредит?', link: '/uk/get-loan' },
        { name: 'Як погасити кредит?', link: '/uk/pay-loan' }
      ]
    },
    {
      name: 'Кредит за 60 секунд',
      className: uppercaselinkclass,
      link: '/uk/sms-kredit'
    },
    { name: 'Акції', className: '', link: '/uk/category/akcii' },
    {
      name: 'Новини та статті',
      className: 'mobile-item dropdawn',
      children: [
        { name: 'Новости', link: '/uk/category/novosti' },
        { name: 'Статьи', className: '', link: '/uk/category/stati' }
      ]
    },
    { name: 'Контакти', className: '', link: '/uk/contacts' },
    {
      name: 'Кабінет',
      className: 'cabinet',
      link: cabinetlinkUa
    }
  ]
};

export const USEFUL_LINKS = {
  ru: [
    { name: 'Как погасить кредит?', link: '/pay-loan' },
    { name: 'Как получить кредит?', link: '/get-loan' },
    { name: 'О компании', link: '/o-kompanii' },
    { name: 'Вакансии', link: '/vakansii' },
    { name: 'SMS кредит', link: '/sms-kredit' },
    { name: 'Контакты', link: '/contacts' },
    { name: 'Финансовая отчетность', link: '/finansovaya-otchetnost' },
    { name: 'Сотрудничество', link: '/cooperation' },
    { name: 'Новости', link: '/category/novosti' },
    { name: 'Статьи', link: '/category/stati' },
    { name: 'Акции', link: '/category/akcii' },
  ],
  uk: [
    { name: 'Як погасити кредит?', link: '/uk/pay-loan' },
    { name: 'Як отримати кредит?', link: '/uk/get-loan' },
    { name: 'Про компанію', link: '/uk/o-kompanii' },
    { name: 'Вакансії', link: '/uk/vakansii' },
    { name: 'SMS кредит', link: '/uk/sms-kredit' },
    { name: 'Контакти', link: '/uk/contacts' },
    { name: 'Фінансова звітність', link: '/uk/finansovaya-otchetnost' },
    { name: 'Співробітництво', link: '/uk/cooperation' },
    { name: 'Новини', link: '/uk/category/novosti' },
    { name: 'Статті', link: '/uk/category/stati' },
    { name: 'Акції', link: '/uk/category/akcii' },
  ]
};
