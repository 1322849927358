import React from 'react';
import PropTypes from 'prop-types';

import './MenuBtn.scss';

const MenuBtn = ({ isMobileOpen, openMobile, openMobileMenu }) => {
  return (
    <div
      className={`nav-btn ${isMobileOpen ? 'mobile-open' : 'mobile-close'}`}
      onClick={() => {
        openMobile(isMobileOpen => !isMobileOpen);
        openMobileMenu();
      }}
    >
      <span />
    </div>
  );
};

export default MenuBtn;

MenuBtn.propTypes = {
  isMobileOpen: PropTypes.bool,
  openMobile: PropTypes.func,
  openMobileMenu: PropTypes.func
};
