import React, { useState } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import './Tags.scss';

const Tags = ({ edges, title, cult }) => {
  const [isPagesListOpen, openPagesList] = useState(false);
  const popularPagesArr = edges.reduce((
    pages,
    { node: { frontmatter: { anchor, path, lang } } }
  ) => {
    if (lang === cult && pages.length < 16) {
      pages.push({ anchor, path });
    }
    return pages;
  }, []);

  return (
    <div className='tags'>
      <p onClick={() => openPagesList(!isPagesListOpen)} className='tags-title'>
        {title}
      </p>
      <ul
        className={`tags-list ${
          isPagesListOpen ? 'tags-list-open' : 'tags-list-close'
        }`}
      >
        {popularPagesArr.map(({ anchor, path }) => (
          <li key={path} className='tags-list-item'>
            <Link className='tags-list-item-link' to={path}>
              {anchor}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Tags;

Tags.propTypes = {
  edges: PropTypes.array,
  title: PropTypes.string,
  cult: PropTypes.string,
};
