import React, { useState, useContext } from 'react';
import InputMask from 'react-input-mask';
import Button from '../Common/Button';

import layoutContext from '../layoutContext';
import { MODAL_TOKENS } from '../../constants/Languages';

import './ModalForm.scss';

const ModalForm = () => {
  const { cult } = useContext(layoutContext);
  const [isNameValid, nameValidator] = useState(true);
  const [isPhoneValid, phoneValidator] = useState(true);
  const [isMessageValid, messageValidator] = useState(true);
  const [nameValue, setNameValue] = useState('');
  const [phoneValue, setPhoneValue] = useState('');
  const [massageValue, setMassageValue] = useState('');

  const {
    contact,
    callTime,
    necessary,
    inputName,
    inputPhone,
    inputMessage } = MODAL_TOKENS[cult];

  function handleSubmit(e) {
    e.preventDefault();
    if (nameValue.length <= 1) {
      nameValidator(false);
    } else {
      nameValidator(true);
    }

    if (phoneValue.includes('_') || phoneValue === '') {
      phoneValidator(false);
    } else {
      phoneValidator(true);
    }

    if (massageValue.length <= 2) {
      messageValidator(false);
    } else {
      messageValidator(true);
    }

    const formData = new FormData();

    const data = {
      name: nameValue,
      phone: phoneValue,
      massage: massageValue,
    };

    for (let name in data) {
      formData.append(name, data[name]);
    }

    fetch('/sendMail', {
      method: 'POST',
      body: formData
    })
      .then(res => res.text())
      // eslint-disable-next-line no-console
      .catch(e => console.error(e));

    setNameValue('');
    setPhoneValue('');
    setMassageValue('');
  }

  return (
    <form
      onSubmit={handleSubmit}
      className='modal-form'
      id='inputForm'
    >
      <div className='form-group'>
        <p className='input-name'>{`${contact} *`}</p>
        <InputMask
          className='modal-input'
          type='text'
          placeholder={inputName}
          value={nameValue}
          onChange={e => setNameValue(e.target.value)}
        />
        {!isNameValid && (
          <p className='input-error'>
            {`${necessary} «${contact}».`}
          </p>
        )}
      </div>
      <div className='form-group'>
        <p className='input-name'>Номер телефона *</p>
        <InputMask
          className='modal-input'
          mask='(999)999-99-99'
          type='tel'
          placeholder={inputPhone}
          value={phoneValue}
          onChange={e => setPhoneValue(e.target.value)}
        />
        {!isPhoneValid && (
          <p className='input-error'>
            {`${necessary} «Номер телефона».`}
          </p>
        )}
      </div>
      <div className='form-group'>
        <p className='input-name'>{`${callTime} *`}</p>
        <textarea
          className='modal-textarea'
          placeholder={inputMessage}
          value={massageValue}
          onChange={e => setMassageValue(e.target.value)}
        />
        {!isMessageValid && (
          <p className='input-error'>
            {`${necessary} «${callTime}».`}
          </p>
        )}
      </div>
      <div className='form-button'>
        <Button
          name='btn btn-primary btn-submit'
          text='Отправить'
          type='submit'
        />
      </div>
    </form>
  );
};

export default ModalForm;
