import React, { useContext, useCallback } from 'react';
import { Link } from 'gatsby';

import { LANGUAGES } from '../../constants/Languages';

import './LanguagesSelect.scss';
import layoutContext from '../layoutContext';

const langToPath = {
  uk: '/uk',
  ru: '',
};

//regexp for getting path without locale prefix
const pathre = /^(\/uk)?(\/.*)?/;

const LanguageSelect = () => {
  const { cult, pathname } = useContext(layoutContext);
  const [, , path] = pathname.match(pathre);

  const pathToggle = useCallback(() => {
    const nextLang = LANGUAGES.filter((it) => it !== cult);
    return `${langToPath[nextLang]}${path ?? '/'}`;
  }, [cult, pathname]);

  return (
    <div className='language-select'>
      {LANGUAGES.map((lang) => (
        <Link
          key={lang}
          aria-label={'Toggle language'}
          to={pathToggle()}
          className='link'
        >
          <div className={`language-selector ${lang === cult ? 'active' : ''}`}>
            {lang}
          </div>
        </Link>
      ))}
    </div>
  );
};

export default LanguageSelect;
