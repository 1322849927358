import React from 'react';
import Loadable from 'react-loadable';

import LoadingComponent from './LoadingComponent';
import FingerPrint from './FingerPrint';

function getCookie(name) {
  if (typeof document !== 'undefined') {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return true;
    } else {
      return;
    }
  }
}

const LazyComponent = Loadable({
  loader: () => {
    if (getCookie('x-fp')) {
      return import('./LoadingComponent');
    } else {
      return import('./FingerPrint');
    }
  },
  loading: LoadingComponent,
  render(loaded, { pathChange }) {
    return <FingerPrint pathChange={pathChange} />;
  },
});

export default LazyComponent;

