import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const FooterDropdawn = ({ className, name, pages }) => {

  const dropdawn = useRef(null);
  const dropdawnLink = useRef(null);
  if(typeof window !== 'undefined') {
    useEffect(() => {
      document.addEventListener('click', clickOutside);
    }, []);

    useEffect(() => {
      document.removeEventListener('click', clickOutside);
    }, []);
    /* eslint-disable-next-line no-inner-declarations */
    function clickOutside(e) {
      if (dropdawn &&
        !dropdawn.current.contains(e.target) &&
        !dropdawnLink.current.contains(e.target)
      ) {
        openDropdawn(false);
      }
    }
  }
  const [isDropdawnOpen, openDropdawn] = useState(false);
  return (
    <li
      className={`footer-list-item ${className}`}
      onClick={() => openDropdawn(!isDropdawnOpen)}
      ref={dropdawnLink}
    >
      <div className='footer-dropdawn-container'>
        <ul
          ref={dropdawn}
          className={`footer-dropdawn-items ${
            isDropdawnOpen
              ? 'footer-dropdawn-items-open'
              : 'footer-dropdawn-items-close'
          }`}
        >
          {pages.map(({ link, name }) => (
            <li key={link} className='footer-list-item-dropdawn'>
              <Link className='footer-list-item-dropdawn-link' to={link}>
                {name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <a className='footer-page-link'>{name}</a>
    </li>
  );
};

export default FooterDropdawn;

FooterDropdawn.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  pages: PropTypes.array
};
