import React, { useContext } from 'react';

import layoutContext from '../layoutContext';

import './Licensing.scss';
import { FOOTER_LICENSE } from '../../constants/Languages';

const Licensing = () => {
  const { cult } = useContext(layoutContext);
  const { title, content, tradeMarkInfo } = FOOTER_LICENSE[cult];
  return (
    <div className='footer-licensing'>
      <p className='footer-licensing-item'>{title}</p>
      <p className='footer-licensing-item'>{content}</p>
      <p className='footer-licensing-item'>{tradeMarkInfo}</p>
    </div>
  );
};

export default Licensing;
