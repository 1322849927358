import React, { useState } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import { MONTHS } from '../../constants/Languages';

const LastNews = ({ title, edges, cult }) => {
  const [isPagesListOpen, openPagesList] = useState(false);

  const lastNews = edges.reduce((
    pages,
    { node: { frontmatter: { headLine, path, category, lang, date } } }
  ) => {
    if (lang === cult && category === 'articles' && pages.length < 3) {
      const year = date.slice(0, 4);
      const number = date.slice(8, 10);
      const month = MONTHS[lang][+date.slice(5, 7) - 1];
      const fotmatDate = `${number} ${month} ${year}`;
      pages.push({ headLine, path, date: fotmatDate });
    }
    return pages;
  }, []);

  return (
    <div className='pages'>
      <p
        onClick={() => openPagesList(!isPagesListOpen)}
        className='pages-title'
      >
        {title}
      </p>
      <ul
        className={`
                pages-list
                ${isPagesListOpen ? 'pages-list-open' : 'pages-list-close'}`
        }
      >
        {lastNews.map(({ headLine, path, date }) => (
          <li
            key={path}
            className='pages-list-item pages-list-item-lastNews'
          >
            <Link
              className={`
                      pages-list-item-link
                      pages-list-item-link-lastNews
                    `}
              to={path}
            >
              {headLine}
              <p className='lastNews-links'>{date}</p>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LastNews;

LastNews.propTypes = {
  title: PropTypes.string,
  edges: PropTypes.array,
  cult: PropTypes.string,
};
