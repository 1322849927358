import React from 'react';
import PropTypes from 'prop-types';

import './Phone.scss';

const Phone = ({ phone }) => (
  <a href={/^\d+$/.test(phone)?`tel: ${phone}`:`tel: +38${phone.replace(/[.*+?^${}()|[\]\\-\s]/g, '')}`} className='phone'>
    {phone}
  </a>
);

export default Phone;

Phone.propTypes = {
  phone: PropTypes.string
};
