import fbFooter from '../images/socialIcons/footer/fb-icon.svg';
import instFooter from '../images/socialIcons/footer/insta-icon.svg';
import tgFooter from '../images/socialIcons/footer/tg-icon.svg';
import twFooter from '../images/socialIcons/footer/twitter-icon.svg';
import ytFooter from '../images/socialIcons/footer/yt-icon.svg';
import fbHeader from '../images/socialIcons/header/fb-icon.svg';
import instHeader from '../images/socialIcons/header/insta-icon.svg';
import tgHeader from '../images/socialIcons/header/tg-icon.svg';
import twHeader from '../images/socialIcons/header/twitter-icon.svg';
import ytHeader from '../images/socialIcons/header/yt-icon.svg';

export const FOOTER_ICONSET = [
  {
    socialIcon: fbFooter,
    alt: 'Global credit facebook',
    link: 'https://www.facebook.com/globalcredit.ua/'
  },
  {
    socialIcon: instFooter,
    alt: 'Global credit instagram',
    link: 'https://www.instagram.com/globalcredit.ua/?hl=ru'
  },
  {
    socialIcon: tgFooter,
    alt: 'Global credit Telegram',
    link: 'https://t.me/globalcredit'
  },
  {
    socialIcon: twFooter,
    alt: 'Global credit Twitter',
    link: 'https://twitter.com/GlobalcreditUa'
  },
  {
    socialIcon: ytFooter,
    alt: 'Global credit YouTube',
    link: 'https://www.youtube.com/channel/UC-oNJDoDSCmOQy4eZ3_s18Q'
  }
];

export const HEADER_ICONSET = [
  {
    socialIcon: fbHeader,
    alt: 'Global credit facebook',
    link: 'https://www.facebook.com/globalcredit.ua/'
  },
  {
    socialIcon: instHeader,
    alt: 'Global credit instagram',
    link: 'https://www.instagram.com/globalcredit.ua/?hl=ru'
  },
  {
    socialIcon: tgHeader,
    alt: 'Global credit Telegram',
    link: 'https://t.me/globalcredit'
  },
  {
    socialIcon: twHeader,
    alt: 'Global credit Twitter',
    link: 'https://twitter.com/GlobalcreditUa'
  },
  {
    socialIcon: ytHeader,
    alt: 'Global credit YouTube',
    link: 'https://www.youtube.com/channel/UC-oNJDoDSCmOQy4eZ3_s18Q'
  }
];
