import React, { useContext } from 'react';
import './FooterInfo.scss';
import { FOOTER_TOKENS } from '../../constants/Languages';
import layoutContext from '../layoutContext';

const FooterInfo = () => {
  const { cult } = useContext(layoutContext);
  return (
    <p className='footer-info-text'>{FOOTER_TOKENS[cult].infoText}</p>
  );
};

export default FooterInfo;
