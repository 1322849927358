import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import SocialLinks from '../Common/SocialLinks/SocialLinks';
import Button from '../Common/Button';
import Phone from './Phone';

import { HEADER_ICONSET } from '../../constants/SocialIcons';
import { PHONES } from '../../constants/Phones';
import { HEADER_TOKENS } from '../../constants/Languages';
import layoutContext from '../layoutContext';

import './Contacts.scss';


const Contacts = ({ isModalOpen, openModal, fixMenu }) => {
  const { cult } = useContext(layoutContext);
  const { orderCall } = HEADER_TOKENS[cult];
  return (
    <div className='contacts'>
      <div className='contacts-container'>
        <SocialLinks socialSet={HEADER_ICONSET} />
        <div className='contacts-phone'>
          {PHONES.map(phone => (
            <Phone key={phone} phone={phone} />
          ))}
        </div>
        <Button
          name='btn btn-primary btn-contacts'
          text={orderCall}
          isOpen={isModalOpen}
          open={openModal}
          fixMenu={fixMenu}
        />
      </div>
    </div>
  );
};

export default Contacts;

Contacts.propTypes = {
  isModalOpen: PropTypes.bool,
  openModal: PropTypes.func,
  fixMenu: PropTypes.func
};
