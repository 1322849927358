import React from 'react';
import PropTypes from 'prop-types';

const Button = ({ name, text, isOpen, open, fixMenu, type = 'button' }) => (
  <button
    type={type}
    onClick={() => {
      open && open(!isOpen);
      fixMenu && fixMenu();
    }}
    className={name}
  >
    {text}
  </button>
);

export default Button;

Button.propTypes = {
  name: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
  isOpen: PropTypes.bool,
  open: PropTypes.func,
  fixMenu: PropTypes.func
};
