/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-len */
/* eslint-disable no-cond-assign */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

import Header from './Header/Header';
import Footer from './Footer/Footer';
import LazyComponent from './Fingerprint/LazyComponent';

import { apiTrackLink } from '../constants/ApiLink';
import { LANGUAGES } from '../constants/Languages';
import layoutContext from './layoutContext';
import './layout.scss';

// regexp for getting language name from path
const pathre = /^\/(uk)?(\/.*)?/;

const DAYS = 60;

const Layout = ({ children, location: { pathname } }) => {
  const [, pathToLang] = pathname.match(pathre);
  const [cult, setCult] = useState(pathToLang || LANGUAGES[1]);
  const [path, setPath] = useState();
  const [counter, setCounter] = useState({ summCount: 0, daysCount: 0 });
  const [openModal] = useState(false);

  if (typeof document !== 'undefined') {
    document.cookie = `counter=${JSON.stringify(counter)}; path=/`;
  }

  useEffect(() => {
    const today = new Date();
    const deleteCookies = new Date();
    deleteCookies.setTime(today.getTime() + 3600000 * 24 * DAYS);
    if (Object.keys(getQueryParams(location.search)).length) {
      document.cookie = `getParams = ${JSON.stringify(
        getQueryParams(location.search)
      )}; expires = ${deleteCookies.toUTCString()}; path = /`;
    } else if (
      !Object.keys(getQueryParams(location.search)).length &&
      !getCookie('getParams')
    ) {
      document.cookie = `getParams=${JSON.stringify(
        {}
      )}; expires=${deleteCookies.toUTCString()}; path=/`;
    } else {
      `getParams=${JSON.stringify(
        {}
      )}; expires=${deleteCookies.toUTCString()}; path=/`;
    }
  }, []);

  useEffect(() => {
    try {
      if (typeof Notification !== undefined) {
        if (
          !getCookie('notifications') &&
          Notification.permission === 'default'
        ) {
          openModal(true);
        }
      } else {
        if (!getCookie('notifications')) {
          openModal(true);
        }
      }
    } catch {
      console.log('No notifiactions availible in browser');
    }
  }, []);

  const getCookie = useCallback((name) => {
    if (typeof document !== 'undefined') {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) {
        return parts.pop().split(';').shift();
      } else {
        return false;
      }
    }
    return null;
  }, []);

  const pathChange = useCallback(() => {
    const body = {
      'x-fp': getCookie('x-fp'),
    };
    if (
      typeof window !== 'undefined' &&
      path !== window.location.pathname &&
      getCookie('x-fp')
    ) {
      setPath(window.location.pathname);
      fetch(apiTrackLink, {
        method: 'POST',
        mode: 'no-cors',
        cache: 'no-cache',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
        .then((response) => response.json())
        .catch((error) => error);
    }
  }, [path, counter]);

  function getQueryParams(link) {
    link = link.split('+').join(' ');

    const params = {},
      re = /[?&]?([^=]+)=([^&]*)/g;

    let tokens;

    while ((tokens = re.exec(link))) {
      params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
    }

    return params;
  }

  pathChange();

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              seoTitle {
                ru
                uk
              }
            }
          }
          pages: allMarkdownRemark(
            limit: 2000
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { fileAbsolutePath: { regex: "/content/" } }
          ) {
            totalCount
            edges {
              node {
                frontmatter {
                  title
                  path
                  tags
                }
              }
            }
          }
        }
      `}
      render={() => (
        <layoutContext.Provider
          value={{ cult, setCult, pathname, counter, setCounter }}
        >
          <Header />
          <LazyComponent pathChange={pathChange} />
          {children}
          <Footer />
        </layoutContext.Provider>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object,
};

export default Layout;
