import React, { useState } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import './UsefulLinks.scss';

const UsefulLinks = ({ pages, title }) => {
  const [isPagesListOpen, openPagesList] = useState(false);

  const usefulLinksPages = pages.reduce((
    pages,
    { name, link }
  ) => {
    pages.push({ headLine: name, path: link });
    return pages;
  }, []);


  return (
    <div className='pages'>
      <p
        onClick={() => openPagesList(!isPagesListOpen)}
        className='pages-title'
      >
        {title}
      </p>
      <ul
        className={`pages-list ${
          isPagesListOpen ? 'pages-list-open' : 'pages-list-close'
        }`}
      >
        {usefulLinksPages.map(({ headLine, path }) => (
          <li key={path} className='pages-list-item'>
            <Link className='pages-list-item-link' to={path}>
              {headLine}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UsefulLinks;

UsefulLinks.propTypes = {
  pages: PropTypes.array,
  title: PropTypes.string,
  cult: PropTypes.string,
};
